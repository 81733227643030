*,*::before,*::after{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
}

html{
  font-size: 62.5%;
  scroll-behavior: smooth;
}