/* font-family: 'Kanit', sans-serif;
font-family: 'Montserrat', sans-serif;
font-family: 'Open Sans', sans-serif;
font-family: 'Poppins', sans-serif;
font-family: 'Roboto', sans-serif; */

.container{
    width: 100%;
    min-height: 100dvh;
}

.header{
    width: 100%;
    position: sticky;
    top: 0;
    left: 0;
    padding: 1rem 3rem 1rem 3rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 99;
    background-color: white;
}
.logo_container{
    width: 5rem;
    height: 5rem;
}
.logo_container .logo{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.menu_links{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 3rem;
}
.link{
    font-size: 1.5rem;
    color: #333;
    font-family: 'Montserrat',sans-serif;
}
.sub_container{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    padding: 0 3rem 2rem 3rem;
}

/* MAIN IMAGE CONTAINER */

.main_image_container{
    width: 100%;
    position: relative;
}
.main_image_container .main_image{
    /* background: linear-gradient(90deg,rgba(0, 0, 0, 0.385),rgba(0, 0, 0, 0.476)),url("../staticImages/concert1.avif"); */
    background:url("../staticImages/banner1.png");
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 50rem;
}

.main_image_details{
    position: absolute;
    bottom: 10%;
    left: 50%;
    transform: translate(-50%,0);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-direction: column;
    gap: 1rem;
}

.main_image_details h3{
    color: white;
    font-size: 3rem;
    font-family: 'Montserrat',sans-serif;
    font-weight: bold;
    letter-spacing: 0.2rem;
    /* background: linear-gradient(to right,#0b0b0b,#101010);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; */
}

.btn{
    font-size: 2rem;
    font-family: 'Montserrat',sans-serif;
    color: white;
    border: 0.1rem solid white;
    background-color: transparent;
    /* border: 0.1rem solid black; */
    /* background-color: black; */
    padding: 1rem 2rem 1rem 2rem;
    border-radius: 0.2rem;
    transition: all 0.3s;
}
.btn:hover{
    background-color: black;
    border: 0.1rem solid black;
}
.btn:active{
    transform: scale(1.1);
}

/* VIDEOS SECTION */

.videos_section{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
}

.heading_title{
    text-align: center;
    font-size: 2.5rem;
    font-family: 'Montserrat',sans-serif;
    font-weight: bold;
    color: #333;
}

.videos_card_grid{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1rem;
}

.video_card{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    background-color: black;
    border-radius: 0.2rem;
    overflow: hidden;
}

.video_details_container{
    padding: 0 2rem 1rem 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}
.video_details_container p{
    color: white;
    font-size: 1.5rem;
    font-family: 'Montserrat',sans-serif;
}

.icon_container{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1.5rem;
}

.like_icon{
    color: white;
    font-size: 1.5rem;
    cursor: pointer;
}

.icon_container p{
    color: white;
    font-size: 1.5rem;
}

@media screen and (max-width:1000px){
    .sub_container{
        padding: 0 0.5rem 2rem 0.5rem;
    }
    .main_image_container .main_image{
        height: 30rem;
        object-fit: cover;
    }
    .videos_card_grid{
        grid-template-columns: 1fr 1fr;
    }
}

@media screen and (max-width:600px){
    .main_image_details h3{
        font-size: 2rem;
    }
    
    .btn{
        font-size: 1rem;
    }
    .videos_card_grid{
        grid-template-columns: 1fr;
        gap: 2rem;
    }
}

@media screen and (max-width:500px){
    .main_image_container .main_image{
        height: 30rem;
        background-repeat: contain;
    }
}

@media screen and (max-width:450px){
    .main_image_container .main_image{
        height: 30rem;
        background-position: -30px 0;
        background-repeat: contain;
    }
}


/* LOADING */

.loading_container{
    display: flex;
    justify-content: center;
    align-content: center;
    width: 100%;
}
.loader {
    width: 48px;
    height: 48px;
    border: 5px solid #FFF;
    border-bottom-color: #FF3D00;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
    }

    @keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
    } 